<template>
    <CCard class="new-project-scope">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem>
                <router-link :to="{ name: 'ProjectScopes' }">Scopes</router-link>
            </CBreadcrumbItem>

            <CBreadcrumbItem active>
                <template v-if="$route.params.scopeId">
                    {{ origin ? origin.scopeName : '' }}
                </template>
                <template v-else> New Scope</template>
            </CBreadcrumbItem>
        </teleport>

        <CCardBody class="new-project-scope__card-body"
                   :class="{ 'new-project-scope__card-body--loading': loadingPage }">

            <CSpinner v-if="loadingPage && origin" color="primary"/>

            <template v-else>
                <ConfirmDialog ref="confirmDialog"/>

                <div class="new-project-scope__left-side">
                    <div class="new-project-scope__actions">
                        <button type="button" class="new-project-scope__button new-project-scope__button--text"
                                @click="goToAllScopes">
                            <CIcon name="cilArrowLeft"
                                   class="new-project-scope__icon new-project-scope__icon--arrow-left"/>
                            <span
                                class="new-project-scope__font new-project-scope__font--p1 new-project-scope__font--link">
                                All Scopes
                            </span>
                        </button>

                        <button type="button" class="new-project-scope__button new-project-scope__button--text"
                                :disabled="viewOnly || !primaryTemplateId"
                                @click="toggleDocumentPreviewer('templates', primaryTemplateId, 'preview', primaryTemplateExtension)">
                            <CIcon name="cilFile" class="new-project-scope__icon new-project-scope__icon--file"/>

                            <span v-if="!primaryTemplateId"
                                  v-c-tooltip="{ content: 'Click here to see the preview of the Primary Contract document from the selected package', placement: 'top' }"
                                  class="new-project-scope__font new-project-scope__font--p1 new-project-scope__font--link new-project-scope__font--tooltip">
                                Preview
                            </span>

                            <span v-else
                                  class="new-project-scope__font new-project-scope__font--p1 new-project-scope__font--link">
                                Preview
                            </span>
                        </button>
                    </div>

                    <CForm class="new-project-scope__form">
                        <div class="new-project-scope__form-content">
                            <CRow>
                                <CCol md="6">
                                    <CNInput v-model="form.scopeName" label="Add Scope Name" required
                                             :disabled="viewOnly" :invalid="!!validationErrors['scopeName']"
                                             :error="validationErrors['scopeName']" @blur="validateField('scopeName')"/>
                                </CCol>
                            </CRow>

                            <CRow class="new-project-scope__mt-24">
                                <CCol md="6">
                                    <CNSelect v-model="form.selectedCompany" :options="companies"
                                              label="Select a Company" search :disabled="viewOnly"
                                              @change="validateField('selectedCompany')"/>
                                </CCol>

                                <CCol md="6">
                                    <CNSelect :model-value="scenarioId" :options="scenario" label="Scenario" disabled/>
                                </CCol>
                            </CRow>

                            <CRow class="new-project-scope__mt-24">
                                <CCol md="6">
                                    <CNSelect v-model="form.companyOffice" :options="companyOffices"
                                              :loading="loadingCompanyOffices" can-deselect label="Company Office"
                                              :disabled="viewOnly || loadingCompanyOffices || !companyOffices.length"/>
                                </CCol>

                                <CCol md="6">
                                    <CNSelect v-model="form.companyLicense" :options="companyLicenses"
                                              :loading="loadingCompanyLicenses" can-deselect label="Company License"
                                              :disabled="viewOnly || loadingCompanyLicenses || !companyLicenses.length"/>
                                </CCol>
                            </CRow>

                            <CRow class="new-project-scope__mt-24">
                                <CCol md="6">
                                    <CNSelect v-model="form.selectedCompanyType" :options="companyTypes"
                                              :disabled="viewOnly" label="Select Company Type"/>
                                </CCol>

                                <CCol md="6">
                                    <CNSelect v-model="form.selectedPackage" :options="folders" label="Select Package"
                                              required :aria-disabled="$route.params.scopeId"
                                              :disabled="viewOnly || $route.params.scopeId"
                                              :error="validationErrors['selectedPackage']"
                                              :invalid="!!validationErrors['selectedPackage']"
                                              @change="validateField('selectedPackage')"/>
                                </CCol>
                            </CRow>

                            <CRow class="new-project-scope__mt-24">
                                <CCol md="6">
                                    <CNInputDate v-model="form.dueDate" label="Due date" required :disabled="viewOnly"
                                                 :invalid="!!validationErrors['dueDate']"
                                                 :error="validationErrors['dueDate']" @blur="validateField('dueDate')"
                                                 @touchField="validateField('dueDate')"/>
                                </CCol>

                                <CCol md="6">
                                    <CNInput v-model="modifyBudget" mask="money"
                                             :mask-money="{ decimal: '.', thousands: ',', prefix: '$ ', precision: 2, masked: true, }"
                                             required label="Scope Budget" :disabled="viewOnly"
                                             :invalid="!!validationErrors['budget']" :error="validationErrors['budget']"
                                             @blur="validateField('budget')"/>
                                </CCol>
                            </CRow>

                            <CRow class="new-project-scope__mt-24">
                                <CCol md="6">
                                    <CNInput v-model="form.contractNumber" label="Contract Number" :disabled="viewOnly"
                                             :invalid="!!validationErrors['contractNumber']"
                                             :error="validationErrors['contractNumber']"
                                             @blur="validateField('contractNumber')"/>
                                </CCol>

                                <CCol md="6"/>
                            </CRow>

                            <div class="new-project-scope__mt-24 new-project-scope__form-scope">
                                <h2 class="new-project-scope__heading new-project-scope__heading--h2">
                                    Scope
                                </h2>

                                <div class="new-project-scope__mt-16">
                                    <WorkList ref="workList" :work-list="form.scope" :disabled="viewOnly"
                                              @updateList="updateWorkList"/>
                                </div>

                                <div class="new-project-scope__mt-16">
                                    <CNTextarea v-model="form.scopeDescription"
                                                class="new-project-scope__scope-description" label="Scope description"
                                                required :disabled="viewOnly"
                                                :invalid="!!validationErrors['scopeDescription']"
                                                :error="validationErrors['scopeDescription']"
                                                @blur="validateField('scopeDescription')"/>
                                </div>

                                <div class="new-project-scope__mt-16">
                                    <CNTextarea v-model="form.exclusions"
                                                textarea-class="new-project-scope__scope-exclusions" label="Exclusions"
                                                :disabled="viewOnly" :invalid="!!validationErrors['exclusions']"
                                                :error="validationErrors['exclusions']"
                                                @blur="validateField('exclusions')"/>
                                </div>
                            </div>

                            <div class="mt-3">
                                <div class="new-project-scope__custom-fields">
                                    <CustomFieldBlock ref="scopeBlock" entity="scope"
                                                      :fields="customFieldsList.map(f => ({ ...f, name: 'Scope' }))"
                                                      :form-fields="customFieldsValues" @update="onCustomFieldUpdate"/>
                                </div>

                                <AddCustomField entity="scope" @update="onCustomFieldUpdate"/>
                            </div>

                            <div v-if="contractDocuments.length" class="new-project-scope__form-docs">
                                <h4 class="new-project-scope__heading new-project-scope__heading--h4">
                                    List of Updated Contracts Documents
                                </h4>

                                <ul class="new-project-scope__mt-24 new-project-scope__form-docs-list">
                                    <li v-for="(item, index) in contractDocuments" :key="index"
                                        class="new-project-scope__form-docs-item">
                                        <CIcon class="new-project-scope__icon new-project-scope__icon--file"
                                               name="cilFile"/>

                                        <span class="new-project-scope__font new-project-scope__font--p3">
                                            {{ item.name }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <FixedBottomPanel style="margin-top: auto" :buttons="fixedBottomPanelButtons"
                                          :disabled-delete-button="!$route.params.scopeId" delete-text="Delete scope"
                                          @deleteHandler="deleteScope"/>
                    </CForm>
                </div>

                <div class="new-project-scope__right-side">
                    <ScopeApprovals :status="scopeStatusForScopeApproval" :workflow-name="workflowName"
                                    :final-scope-approval-users="finalScopeApprovalUsers"
                                    :scope-draft-users="scopeDraftUsers" :loading-approval-data="loadingApprovalData"
                                    :chats-data="chatsData" :view-only="viewOnly"
                                    :scope-has-assigned-tasks="scopeHasAssignedTasks"
                                    class="new-project-scope__box new-project-scope__approval"
                                    @updateProjectData="updateProjectData"/>

                    <div class="new-project-scope__box new-project-scope__attachments">
                        <div class="new-project-scope__attachments-header">
                            <div class="new-project-scope__attachments-title">
                                <h4 class="new-project-scope__font new-project-scope__font--h4">
                                    Attachments
                                </h4>

                                <label class="new-project-scope__button new-project-scope__button--text">
                                    <CIcon size="lg" name="cilCloudUpload"
                                           class="new-project-scope__icon new-project-scope__icon--cloud"/>

                                    <input ref="inputFiles" type="file" multiple
                                           accept=".pdf,.png,.jpg" hidden
                                           @click="handleCheckPermission($event)" @change="uploadFiles($event)"/>

                                    <span
                                        class="new-project-scope__font new-project-scope__font--p2 new-project-scope__font--link">
                                        Upload file
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="new-project-scope__attachments-content"
                             :class="{ 'new-project-scope__attachments-content--center': loadingAttachments || !attachments.length }">
                            <CSpinner v-if="loadingAttachments" color="primary"/>

                            <template v-else>
                                <p v-if="!attachments.length"
                                   class="new-project-scope__font new-project-scope__font--p1 new-project-scope__font--gray text-center">
                                    Attached here documents <br/>
                                    provided by your third party <br/>
                                    (Ex: estimates from a subcontractors) <br/>
                                    <br/>
                                    Supported formats: <br/>
                                    PDF, PNG, JPG
                                </p>

                                <ul v-else class="new-project-scope__attachments-list">
                                    <li v-for="(item, index) in attachments" :key="item.name + index"
                                        class="new-project-scope__attachments-item">
                                        <CFormCheck class="new-project-scope__attachments-checkbox"
                                                    :checked="item.check" :disabled="viewOnly"
                                                    @change="checkAttachment(item, index)"/>

                                        <a class="new-project-scope__button new-project-scope__button--text" href="#"
                                           @click.prevent="toggleDocumentPreviewer('scopes', staticData.scopeId, `attachments/${item.id}/preview`)">
                                            <span class="new-project-scope__attachments-text">{{ item.name }}</span>
                                        </a>

                                        <button type="button"
                                                class="new-project-scope__button new-project-scope__button--text new-project-scope__attachments-delete"
                                                @click="deleteFile(item, index)">
                                            <CIcon name="cilTrash" :icon="cilTrash()"
                                                   class="new-project-scope__icon new-project-scope__icon--trash"/>
                                        </button>
                                    </li>
                                </ul>
                                <p v-if="attachments.length"
                                   class="new-project-scope__font new-project-scope__font--p1 new-project-scope__font--gray text-center">
                                    Please use the checkboxes to select documents that will be sent to the subcontractor
                                    as part of the primary contract package
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </CCardBody>
    </CCard>
    <DocumentPreviewer :visible="documentPreviewer.visible" :type="documentPreviewer.type"
                       :type-id="documentPreviewer.typeId" :route="documentPreviewer.route"
                       :extension="documentPreviewer.extension" @closed="toggleDocumentPreviewer(null, null, null)"/>
</template>

<script>
import WorkList from './WorkList'
import FixedBottomPanel from '@/views/Projects/Project/Components/FixedBottomPanel'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import rules from '@/utils/validator/rules'
import validator from '@/utils/validator'
import ScopeApprovals from './ScopeApprovals'
import {isEqual} from 'lodash/lang'
import InfoIcon from '@/views/Setting/Account/CompanyDetails/components/InfoIcon'
import {mapActions, mapGetters} from 'vuex'
import {notifyByPlan} from '@/services/Notify/Toasts'
import AddCustomField from "@/views/Projects/Project/Info/Details/components/CustomField/AddCustomField.vue";
import CustomFieldBlock from "@/views/Projects/Project/Info/Details/components/CustomField/CustomFieldBlock.vue";
import documentDownload from "@/mixin/documentDownload";
import {cilTrash} from "@coreui/icons";
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";

export default {
    name: 'Index',
    components: {
        DocumentPreviewer,
        CustomFieldBlock,
        AddCustomField,
        ScopeApprovals,
        FixedBottomPanel,
        WorkList,
        ConfirmDialog,
        InfoIcon,
    },
    inject: ['toast', 'mountedComponent'],
    mixins: [documentDownload],
    async beforeRouteLeave() {
        if (this.isEditForm) {
            await this.$refs.confirmDialog
                .confirm({
                    text: this.$t('messages.unsaved'),
                    confirmText: 'Leave',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        return true
                    } else {
                        return Promise.reject(false)
                    }
                })
        } else {
            return true
        }
    },
    data() {
        return {
            validationErrors: {},
            validator: {},

            origin: {},

            scopeStatus: '',
            scopeStatusForScopeApproval: '',
            scopeHasAssignedTasks: false,

            subscribedDraftChat: false,
            subscribedApprovalChat: false,

            loadingApprovalData: false,

            scopeDraftUsers: [],
            finalScopeApprovalUsers: [],

            loadingPage: false,
            loadingAttachments: false,

            projectId: this.$route.params.id,

            companies: [],
            companyTypes: [],
            contractDocuments: [],
            folders: [],
            scenario: [],
            scenarioId: null,
            primaryTemplateId: null,
            primaryTemplateExtension: null,
            workflowName: '',

            companyOffices: [],
            companyLicenses: [],

            loadingCompanyOffices: false,
            loadingCompanyLicenses: false,

            form: {
                scopeName: '',
                selectedCompany: '',
                selectedCompanyType: '',
                selectedPackage: '',
                scope: [],
                scopeDescription: '',
                exclusions: '',

                companyOffice: '',
                companyLicense: '',

                dueDate: '',
                budget: '',

                contractNumber: '',
                custom_fields: {},
            },

            attachments: [],

            staticData: Object.seal({
                scopeId: null,
            }),

            customFieldsList: [],
            customFieldsValues: {},
            customFieldsChanged: false,

            childRef: [
                'scopeBlock'
            ],

            documentPreviewer: {
                visible: false,
                typeId: null,
                type: null,
                route: null,
                extension: null,
            },
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            chatsData: 'chatsData',
            permissions: 'scopePermissions',
            projectPermissions: 'projectPermissions',
        }),
        viewOnly() {
            return this.$route.params.scopeId
                ? !this.permissions.edit
                : !this.projectPermissions.scopeCreate
        },
        fixedBottomPanelButtons() {
            return [
                {
                    text: 'Cancel',
                    disabled: !this.isEditForm,
                    outline: true,
                    event: () => this.handleCancel(),
                },
                {
                    text: 'Save scope',
                    disabled: !(this.customFieldsChanged || this.isEditForm),
                    event: () => this.saveScope(),
                },
            ]
        },
        isEditForm() {
            return !isEqual(this.form, this.origin)
        },
        modifyBudget: {
            get() {
                return this.form.budget
            },
            set(val) {
                const budget = val.replace('$ ', '').replaceAll(',', '')
                this.form.budget = budget
            },
        }
    },
    watch: {
        scopeStatus: {
            handler() {
                const scopeId = this.$route.params.scopeId
                if (!scopeId) return
                this.fetchApprovalsList(scopeId)

                if (!this.subscribedDraftChat) {
                    this.subscribedDraftChat = true

                    if (!this.viewOnly) {
                        this.subscribe({
                            channel: `scope.${this.$route.params.scopeId}-editing`,
                            event: '.scope-chat-editing',
                        })
                    }
                }

                if (
                    !this.subscribedApprovalChat &&
                    ['pending', 'not sent'].includes(this.scopeStatus)
                ) {
                    this.subscribedApprovalChat = true
                    this.subscribe({
                        channel: `scope.${this.$route.params.scopeId}-approving`,
                        event: '.scope-chat-approving',
                    })
                }
            },
        },
        attachments() {
            const scopeId = this.$route.params.scopeId
            const allowedStatus = ['pending', 'not sent'].includes(this.scopeStatus)
            const condition = scopeId && allowedStatus

            if (condition) {
                this.fetchScopeData(scopeId)
            }
        },
        'form.selectedCompany': {
            handler(companyId) {
                if (!companyId) return

                if (!this.loadingPage) {
                    this.form.companyLicense = null
                    this.form.companyOffice = null
                }

                this.fetchCompanyLicenses(companyId)
                this.fetchCompanyOffices(companyId)
            },
        },
    },
    async mounted() {
        this.loadingPage = true
        const scopeId = this.$route.params.scopeId
        this.staticData.scopeId = scopeId

        if (!this.user.permissions?.includes('projects_view')) {
            this.$router.push({name: 'PermissionsError'})
        } else {
            await this.$nextTick(() => {
                this.setRules()
            })

            let requests = []

            requests.push(this.fetchData())

            if (scopeId) {
                requests.push(this.fetchAttachments(scopeId))
                requests.push(this.fetchScopeData(scopeId))
                requests.push(this.fetchInternalApprovalData(scopeId))
                requests.push(this.fetchChatsData())
            }

            await Promise.all(requests).finally(() => (this.loadingPage = false))
        }
    },
    unmounted() {
        if (this.staticData.scopeId) {
            this.subscribedDraftChat &&
            this.unsubscribe(`private-scope.${this.staticData.scopeId}-editing`)
            this.subscribedApprovalChat &&
            this.unsubscribe(`private-scope.${this.staticData.scopeId}-approving`)
        }
    },
    methods: {
        cilTrash() {
            return cilTrash
        },
        toggleDocumentPreviewer(type, typeId, route, extension = null) {
            this.documentPreviewer.visible = !this.documentPreviewer.visible;
            this.documentPreviewer.type = type;
            this.documentPreviewer.typeId = typeId;
            this.documentPreviewer.route = route;
            this.documentPreviewer.extension = extension;
        },
        ...mapActions({
            subscribe: 'subscribe',
            unsubscribe: 'unsubscribe',
            getChatsData: 'getChatsData',
        }),
        onCustomFieldUpdate() {
            this.customFieldsChanged = true;
            this.fetchData();
        },
        setCustomFieldsValues(data) {
            data.custom_fields.forEach(field => {
                this.customFieldsValues[field.custom_field_id] = {
                    field_id: field.custom_field_id,
                    values: field['selected_values'],
                    add_value: field['selected_values']
                }
            })
        },
        setCustomFields() {
            const fieldsList = this.customFieldsList;

            if (fieldsList && fieldsList.length) {
                fieldsList.forEach(field => {
                    if (Array.isArray(field.options)) {
                        let arr = []
                        field.options.forEach((option) => {
                            arr.push({value: option, origin: option})
                        })
                        field.opt = arr
                    }

                    if (field.hasOwnProperty('options') && !field.options) {
                        field.options = []
                    }
                })
            }
            this.customFieldsList.forEach((el) => {
                if (!this.customFieldsValues[el.id])
                    this.customFieldsValues[el.id] = {
                        field_id: el.id,
                        values: null,
                    }
            })
        },
        normalizeCustomFieldValue(customField) {
            if (typeof customField.values === 'boolean') {
                return customField.values ? '1' : '0'
            }

            if (customField.values === null) {
                return ''
            }

            return customField.values
        },
        handleCheckPermission(event) {
            if (this.viewOnly) {
                notifyByPlan()
                event.preventDefault()
            }
        },
        fetchCompanyLicenses(companyId) {
            this.loadingCompanyLicenses = true

            this.$http.setting
                .fetchCompanyLicenses(companyId)
                .then(({data}) => {
                    this.companyLicenses = data.data?.map((el) => ({
                        value: el.id,
                        name: `${el.number} (${el.company_name || 'N/A'}, ${
                            el.state_name || 'N/A'
                        })`,
                    }))
                })
                .finally(() => {
                    this.loadingCompanyLicenses = false
                })
        },
        fetchCompanyOffices(companyId) {
            this.loadingCompanyOffices = true

            this.$http.setting
                .fetchCompanyOffices(companyId)
                .then(({data}) => {
                    this.companyOffices = data.data?.map((el) => ({
                        value: el.id,
                        name: `${el.name} (${el.address_format})`,
                    }))
                })
                .finally(() => {
                    this.loadingCompanyOffices = false
                })
        },
        createOrigin() {
            this.origin = this.$deepClone(this.form)
        },
        fetchChatsData() {
            this.getChatsData({
                scope_id: this.$route.params.scopeId,
                parts: ['editing', 'approving'],
            })
        },
        fetchData() {
            const params = {
                slug: 'scope',
                project_id: this.$route.params.id,
                scope_id: this.$route.params.scopeId,
            };

            return this.$http.common
                .fetchPageData({params})
                .then(({data}) => {
                    this.companies = this.dataAdapterForDropdown(data.data.companies)
                    this.companyTypes = this.dataAdapterForDropdown(data.data.company_types)
                    this.contractDocuments = data.data.contract_documents
                    this.folders = this.dataAdapterForDropdown(data.data.folders)
                    this.scenario = this.dataAdapterForDropdown([data.data.scenario])
                    this.scenarioId = data.data.scenario.id
                    this.primaryTemplateId = data.data.primary_template_id
                    this.primaryTemplateExtension = data.data.primary_template_extension
                    this.workflowName = data.data.workflow.name || ''

                    this.customFieldsList = data.data.custom_fields
                    this.setCustomFields();

                    this.createOrigin()
                })
        },
        fetchScopeData(scopeId) {
            return this.$http.scope
                .fetchScope(scopeId)
                .then(({data: {data}}) => {
                    this.scopeDataAdapter(data);
                    this.setCustomFieldsValues(data);
                })
                .catch(
                    async () => await this.$router.push({name: 'PermissionsError'}),
                )
        },
        scopeDataAdapter(data) {
            this.form.scopeDescription = data.description || '';
            this.form.exclusions = data.exclusions || '';
            this.form.selectedCompany = data.external_company_id ? parseInt(data.external_company_id) : this.form.selectedCompany;
            this.form.selectedCompanyType = data.external_company_type_id;
            this.form.selectedPackage = data.folder_id;
            this.form.scope = data.work_items?.map((el) => {
                return {
                    name: el.name || '',
                    unit: el.unit || '',
                    cost_code: el.cost_code || '',
                    amount: el.amount,
                }
            });
            this.form.scopeName = data.name;

            this.form.companyOffice = data.office_id ? parseInt(data.office_id) : this.form.companyOffice;
            this.form.companyLicense = data.license_id ? parseInt(data.license_id) : this.form.companyLicense;

            this.form.contractNumber = data.contract_number || '';

            this.form.dueDate = data.due_date || '';
            this.form.budget = data.budget || '';

            this.scopeStatus = data.status;
            this.scopeStatusForScopeApproval = data.external_company_id ? data.status : '';
            this.scopeHasAssignedTasks = data.task_assigned;

            this.customFieldsValues = this.customFieldsValues || {};

            this.createOrigin();
        },
        fetchAttachments(scopeId) {
            return this.$http.scope
                .fetchAttachments(scopeId)
                .then(({data: {data}}) => {
                    this.attachments = data?.map((el) => ({
                        ...el,
                        name: el.file_name,
                    }))
                })
        },
        fetchInternalApprovalData(scopeId) {
            this.loadingApprovalData = true

            const requests = [
                this.fetchEditors(scopeId),
                this.fetchApprovalsList(scopeId),
            ]

            return Promise.all(requests).finally(
                () => (this.loadingApprovalData = false),
            )
        },
        fetchEditors(scopeId) {
            return this.$http.scope
                .fetchEditors(scopeId)
                .then(({data: {data}}) => {
                    this.scopeDraftUsers = data
                })
        },
        fetchApprovalsList(scopeId) {
            return this.$http.scope
                .scopeApprovalsList(scopeId)
                .then(({data: {data}}) => {
                    this.finalScopeApprovalUsers = data
                })
        },
        updateProjectData() {
            this.fetchScopeData(this.$route.params.scopeId)
            this.fetchApprovalsList(this.$route.params.scopeId)
        },
        dataAdapterForDropdown(arr, value = 'id', name = 'name') {
            return arr?.map((el) => ({
                value: el[value] || null,
                name: el[name] || null,
            }))
        },
        uploadFiles(event) {
            if (!event.target.files.length) return

            if (this.$route.params.scopeId) {
                this.loadingAttachments = true
                const formData = new FormData()
                formData.append('scope_id', this.$route.params.scopeId)

                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i]
                    if (file.size > 200000000) {
                        this.toast(
                            'error',
                            `The "${file.name}" size must be less than 200MB`,
                        )
                    } else {
                        formData.append('files[]', file)
                    }
                }

                this.$http.scope
                    .addAttachments(formData)
                    .then(({data}) => {
                        this.toast('success', data.message)

                        this.attachments = data.data?.map((el) => ({
                            ...el,
                            name: el.file_name,
                        }))
                    })
                    .finally(() => (this.loadingAttachments = false))
            } else {
                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i]
                    if (file.size > 200000000) {
                        this.toast(
                            'error',
                            `The "${file.name}" size must be less than 200MB`,
                        )
                    } else {
                        this.attachments.push({
                            check: false,
                            name: file.name,
                            file: file,
                        })
                    }
                }
            }

            this.$refs.inputFiles.value = null
        },
        deleteFile(file, index) {
            if (this.viewOnly) {
                notifyByPlan()
                return
            }
            const scopeId = file.scope_id
            const fileId = file.id

            if (scopeId && fileId) {
                this.loadingAttachments = true
                this.$http.scope
                    .deleteAttachment({
                        scope_id: scopeId,
                        attachment_id: fileId,
                    })
                    .then(({data}) => {
                        this.toast('success', data.message)

                        this.attachments = data.data?.map((el) => ({
                            ...el,
                            name: el.file_name,
                        }))
                    })
                    .finally(() => {
                        this.loadingAttachments = false
                    })
            } else {
                this.attachments = this.attachments.filter((el, i) => index !== i)
            }
        },
        checkAttachment(item, index) {
            const scopeId = this.$route.params.scopeId

            if (scopeId && item.id) {
                this.loadingAttachments = true

                this.$http.scope
                    .checkAttachments({
                        scope_id: scopeId,
                        check: Number(!item.check),
                        attachment_id: item.id,
                    })
                    .then(({data: {data}}) => {
                        this.attachments = data?.map((el) => ({
                            ...el,
                            name: el.file_name,
                        }))
                    })
                    .finally(() => {
                        this.loadingAttachments = false
                    })
            } else {
                this.attachments[index].check = !this.attachments[index].check
            }
        },
        saveScope() {
            const formData = new FormData()

            formData.append('project_id', this.$route.params.id)
            formData.append('name', this.form.scopeName)
            formData.append('external_company_id', this.form.selectedCompany || '')
            formData.append('external_company_type_id', this.form.selectedCompanyType || '')
            formData.append('folder_id', this.form.selectedPackage)
            formData.append('description', this.form.scopeDescription)
            formData.append('exclusions', this.form.exclusions)

            Object.keys(this.customFieldsValues).forEach(k => {
                const f = this.customFieldsValues[k];
                const key_value = `custom_fields[${f.field_id}][value]`;
                const key = `custom_fields[${f.field_id}][custom_field_id]`;
                const fieldExist = this.customFieldsList.some(field => field.id === f.field_id)

                if (fieldExist) {
                    formData.append(key, f.field_id)
                    formData.append(key_value, this.normalizeCustomFieldValue(f))
                }
            })

            formData.append('office_id', this.form.companyOffice || '')
            formData.append('license_id', this.form.companyLicense || '')

            this.form.contractNumber &&
            formData.append('contract_number', this.form.contractNumber)

            const dueDate = this.$luxon().fromJSDate(this.form.dueDate)
            this.form.dueDate &&
            formData.append(
                'due_date',
                dueDate.isValid
                    ? dueDate.toFormat('yyyy-LL-dd TT')
                    : this.form.dueDate,
            )

            this.form.budget && formData.append('budget', this.form.budget)

            this.form.scope?.forEach((el, index) => {
                Object.keys(el)?.forEach((key) => {
                    formData.append(`work_items[${index}][${key}]`, el[key])
                })
            })

            if (!this.$route.params.scopeId) {
                this.attachments?.forEach((file, index) => {
                    Object.keys(file)?.forEach((key) => {
                        switch (key) {
                            case 'file':
                                formData.append(`attachments[${index}][${key}]`, file[key])
                                break
                            case 'check':
                                formData.append(
                                    `attachments[${index}][${key}]`,
                                    Number(file[key]),
                                )
                                break
                        }
                    })
                })
            }

            const scopeId = this.$route.params.scopeId
            const isValidData = this.validateAll()
            const isValidDataWorkList = this.$refs.workList.validateAll()
            const validationCondition = isValidData && isValidDataWorkList

            if (validationCondition) {
                if (['pending', 'not sent'].includes(this.scopeStatus)) {
                    this.$refs.confirmDialog
                        .confirm({
                            text: 'If you post changes to the scope, all the users who have already approved or rejected the scope will be required to approve again. Are you sure you want to save changes?',
                            cancelText: 'Cancel',
                            confirmText: 'Save',
                            reverse: true,
                        })
                        .then((res) => {
                            if (res) {
                                this.saveScopeHandler(scopeId, formData)
                            }
                        })
                } else {
                    this.saveScopeHandler(scopeId, formData)
                }
            } else {
                this.scrollToErrors()
            }
        },
        saveScopeHandler(scopeId, formData) {
            const request = scopeId
                ? this.$http.scope.updateScope(scopeId, formData).then((response) => {
                    this.fetchData()

                    return response
                })
                : this.$http.scope.createScope(formData)

            request
                .then((response) => {
                    const scopeId = response.data.data?.id
                    this.toast('success', response.data.message)

                    this.scopeDataAdapter(response.data.data)
                    this.fetchApprovalsList(scopeId)

                    if (!scopeId) return

                    this.customFieldsChanged = false;
                    this.$router.replace({
                        name: 'ProjectScopeEdit',
                        params: {id: this.$route.params.id, scopeId},
                    })
                })
                .catch((error) => {
                    const dictionary = {
                        name: 'scopeName',
                        due_date: 'dueDate'
                    }
                    let errors = error.response.data.errors

                    const errorsKeys = Object.keys(errors)
                    this.toast('error', error.response.data.message)

                    if (!errorsKeys.length) return

                    errorsKeys.forEach((key) => {
                        const firstError = errors[key][0]

                        this.validationErrors[dictionary[key]] = firstError
                    })

                    this.scrollToErrors()
                })
        },
        deleteScope() {
            const scopeId = this.$route.params.scopeId

            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to delete scope ${this.form.scopeName}? All the related tasks will be deleted.`,
                    cancelText: 'Cancel',
                    confirmText: 'Yes, delete',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.$http.scope.deleteScope(scopeId).then(({data}) => {
                            this.toast('success', data.message)
                            this.$router.push({
                                name: 'ProjectScopesList',
                                params: {id: this.$route.params.id},
                            })
                        })
                    }
                })
        },
        async handleCancel() {
            if (this.isEditForm) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.form = this.$deepClone(this.origin)
                        }
                    })
            } else {
                this.form = this.$deepClone(this.origin)
            }
        },
        updateWorkList(list) {
            this.form.scope = list
        },
        goToAllScopes() {
            this.$router.push({
                name: 'ProjectScopesList',
                params: {
                    id: this.$route.params.id,
                },
            })
        },
        scrollToErrors() {
            this.$nextTick(() => {
                let els = document.querySelectorAll('.form-text.text-error')
                if (els.length) {
                    const top = els[0].getBoundingClientRect().top
                    const y = top + window.pageYOffset + -250
                    window.scrollTo({top: y, behavior: 'smooth'})
                }
            })
        },
        setRules() {
            let baseRules = {
                scopeName: [rules.required, rules.strMax(255)],
                selectedPackage: [rules.required],
                scopeDescription: [rules.required],
                contractNumber: [rules.strMax(255)],
                dueDate: [rules.required],
                budget: [rules.required, rules.numMin(1), rules.numMax(1000000000000)],
                exclusions: [],
            }

            this.validator = validator(baseRules)
        },
        validateField(key) {
            this.validationErrors[key] = this.validator.validate(key, this.form[key])
        },
        validateAll() {
            this.setRules()

            const validationResult = this.validator.validateAll(this.form)
            this.validationErrors = this.$deepClone(validationResult.validationErrors)
            return !validationResult.hasErrors
        },
    },
}
</script>

<style lang="scss">
@import 'style';
</style>
